import { ScrollView, StyleSheet } from 'react-native';
import React, { useContext } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import DSRadioButtonWithDescription from '../components/buttons/DSRadioButtonWithDescription';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function DescribeScreen({ navigation }: Props) {
  console.log('DescribeScreen');

  const intro = 'In-tro-vert';
  const extro = 'Ex-tro-vert';
  const ambi = 'Am-bi-vert';

  const { personality, setPersonality, user,mobile } = useContext(SoTContext);

  const setBestDescribed = (bestDescribed: any) => {
    setPersonality(bestDescribed);
  };
  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="Vouch"
        props={navigation}
        steps={[1, 1, 1, 0, 0, 0, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView>
        <View style={{ width: '100%', paddingHorizontal: 20, alignItems: 'flex-start' }}>
          <Text style={styles.title}>How would you best describe {user?.first}?</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>Tell us about {user?.first}'s personality type.</Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            paddingLeft: 10,
            paddingRight: 20,
          }}
        >
          <DSRadioButtonWithDescription
            descHeader={intro}
            description="A typically reserved or quiet person who tends to be introspective; Gains energy through solitude and quiet."
            selected={personality === intro}
            onPress={() => {
              setBestDescribed(intro);
            }}
          />
          <DSRadioButtonWithDescription
            style={{ marginTop: 0 }}
            descHeader={extro}
            description="A typically gregarious and unreserved person; Gains energy while being around others."
            selected={personality === extro}
            onPress={() => {
              setBestDescribed(extro);
            }}
          />
          <DSRadioButtonWithDescription
            style={{ marginTop: 0 }}
            descHeader={ambi}
            description="A person having characteristics of both extrovert and introvert."
            selected={personality === ambi}
            onPress={() => {
              setBestDescribed(ambi);
            }}
          />
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          position: 'absolute',
          bottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard
          disabled={user.phone !== mobile.replace(/[^\w]/g, '') && personality.length === 0}
          label="Next"
          onPress={() => navigation.navigate('PositiveTraits')}
        ></ButtonStandard>
      </View>
    </View>
  );
}
// background: rgb(20,76,135);
// background: linear-gradient(180deg, rgba(20,76,135,1) 0%, rgba(9,63,118,1) 49%, rgba(4,54,103,1) 98%);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
    background: 'FFFFFF',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '90%',
  },
  miniSection: {
    flex: 2,
    paddingTop: 172,
    width: '100%',
    alignItems: 'center',
  },
  verifyNumber: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#6C7570',
    fontWeight: '600',
  },
  knowInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '90%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    textAlign: 'center',
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: 14,
    marginTop: 15,
  },
  requestAgain: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
});
