import { ScrollView, StyleSheet, TextInput, Modal } from 'react-native';
import React, { useContext, useEffect, useState, useRef } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import VerifyIcon from '../components/Illustrations/VerifyIcon';
import NavHeader from '../components/LayoutSections/NavHeader';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import firebase from '../Global/firebase';
import { DS_USER_SEE_SOT_FLOW, SOT_GET_OTP } from '../constants/Events';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function VerifyScreen({ navigation }: Props) {
  console.log('VerifyScreen');

  const { user, setVerificationId, mobile, setMobile } = useContext(SoTContext);
  const [nextReady, setNextReady] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  const recaptchaVerifier = useRef(null);

  useEffect(() => {
    if (mobile.length === 10) {
      success();
    }
  }, [mobile]);

  useEffect(() => {
    if (modalVisible) {
      setError('');
      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      phoneProvider
        .verifyPhoneNumber(properPhone(mobile), recaptchaVerifier.current!)
        .then((id: any) => {
          console.log('sendVerification*1', id);
          setVerificationId(id);
          navigation.navigate('VerificationCode');
        })
        .catch(err => {
          console.error('Error verifying phone number:', err);
          setError('Error verifying phone number: ' + err.toString());
        })
        .finally(() => {
          setModalVisible(false);
        });
    }
  }, [modalVisible]);

  /* firebase phone number verification code */
  // Function to be called when requesting for a verification code
  const sendVerification = () => {
    const tempMobile = mobile;
    const filteredNumber = tempMobile.replace(/[^\w]/g, '');
    // if (user.phone === filteredNumber) {
      const analytics = firebase.analytics();
      console.log('sdsd',analytics);
      
      if (user.phone == filteredNumber) {
        window.alert(`This is how your Sphere of Trust introduces the real you. Let us know what you love and how we can make DatingSphere better. You can email us at ds@datingsphere.com.`);
      }
      if (user.phone == filteredNumber) {        
        analytics.logEvent(DS_USER_SEE_SOT_FLOW, {});
      } else {
        analytics.logEvent(SOT_GET_OTP, {});
      }
    // } else {
      setModalVisible(true);
    // }
  };

  function properPhone(phone: any) {
    return '+1' + phone.replace(/\D/g, '');
  }

  const success = () => {
    console.log('Success: Phone number is properly formatted.');
    setNextReady(nextReadyVerify(mobile));
  };

  const formatPhoneNumber = (text: string) => {
    let formattedNumber = text.replace(/\D/g, '');
    if (formattedNumber.length === 10) {
      formattedNumber = `(${formattedNumber.substring(0, 3)}) ${formattedNumber.substring(
        3,
        6
      )}-${formattedNumber.substring(6, 10)}`;
      setNextReady(nextReadyVerify(mobile));
    } else {
      setNextReady(false);
    }
    setMobile(formattedNumber);
  };

  const nextReadyVerify = (phone: any) => {
    let cleanNum = phone
      .replace('-', '')
      .replace('-', '')
      .replace('-')
      .replace('(', '')
      .replace(')')
      .trim();
    // todo: use a regex to properly match phone number patterns (numbers only, overall length, and from various countries etc...)
    if (cleanNum !== '0000000000') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <View style={styles.container}>
      <Modal visible={modalVisible} animationType="slide">
        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifier}
          firebaseConfig={firebase.app().options}
          attemptInvisibleVerification={true}
        />
      </Modal>
      <NavHeader
        style={{ marginBottom: 20 }}
        isIntroPage={false}
        backButtonNavigation="WhatsNext"
        props={navigation}
        steps={[]}
        width={globalStyles.container.width}
        height="112px"
      />
      <ScrollView style={{ flex: 1, paddingBottom: 10 }}>
        <View style={styles.headerImageArea}>
          <VerifyIcon width={274} height={274} size={274} />
        </View>
        <View style={styles.miniSection}>
          <Text style={styles.title}>Verify that you're a real person</Text>
          <Text style={styles.subTitle}>
            Enter your mobile number to receive a text with a verification code.
          </Text>
          <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          <View style={styles.numberEntry}>
            <Text style={styles.fieldTopLabel}>Mobile Number</Text>
            <TextInput
              placeholderTextColor="#ADB5BD"
              placeholder="(000)000-0000"
              onChangeText={text => formatPhoneNumber(text)}
              value={mobile}
              keyboardType="number-pad"
              maxLength={10}
              // onChangeText={text => {
              //   if (onlyNumbers(clearSymbols(text)) || text === "") {
              //     setMobile(text)
              //     setNextReady(nextReadyVerify(text));
              //   }
              // }
              // }
              style={styles.numberInput}
            ></TextInput>
            <Text style={styles.entryHint}>Only used to verify your identity.</Text>
          </View>
        </View>
        {error && (
          <View style={styles.error}>
            <Text
              style={{
                fontFamily: 'Jost Regular',
                fontSize: 16,
                color: 'red',
              }}
            >
              {error}
            </Text>
          </View>
        )}
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard
          label="Get OTP"
          disabled={!nextReady}
          onPress={() => {
            sendVerification();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  headerImageArea: {
    alignItems: 'center',
    height: globalStyles.container.height < 800 ? 200 : 270,
    paddingVertical: globalStyles.container.height < 800 ? 0 : 20,
  },
  title: {
    paddingHorizontal: 20,
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    paddingHorizontal: 20,
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 20,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    marginHorizontal: 20,
  },
  miniSection: {
    flex: 2,
    paddingTop: 10,
  },
  numberInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: '100%',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
  },
  numberEntry: {
    width: '100%',
    paddingHorizontal: 20,
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 14 : 16,
    marginTop: 15,
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 6,
  },
  error: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
});
