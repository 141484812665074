import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import { SoTProvider } from './Global/SoTContext';
import { USER_ID_KEY } from './Global/api';

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    // const userId ='aeb7d595-5559-4c12-95e6-6c4a2d9d3626';
    // const userId ='3de35c0d-a13b-4b5d-a91b-b66fa4dce3e4';
    const userId = urlParams?.get('user_id');
    console.log('user_id', userId);
    if (userId) {
      window.localStorage.setItem(USER_ID_KEY, userId);
    }

    return (
      <SoTProvider>
        <SafeAreaProvider nativeID="outerPage">
          <Navigation colorScheme="light" />
          <StatusBar />
        </SafeAreaProvider>
      </SoTProvider>
    );
  }
}
