import { ResizeMode, Video } from 'expo-av';
import { AVPlaybackStatus } from 'expo-av/build/AV';
import React, { useRef, useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { globalStyles } from '../Global/GlobalStyles';
import firebase from '../Global/firebase';
import { SOT_CLOSE_FOUNDER_VIDEO } from '../constants/Events';

export default function VideoScreen({ navigation }: { navigation: any }) {
  const [isBuffering, setIsBuffering] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(true);
  const video = useRef(null);

  const _onPlaybackStatusUpdate = (playbackStatus: AVPlaybackStatus) => {
    console.log('!playbackStatus.isLoaded', playbackStatus.isLoaded);

    if (!playbackStatus.isLoaded) {
      setIsBuffering(true);
    } else {
      setIsBuffering(false);
      if (playbackStatus.isBuffering) {
        setIsBuffering(true);
      } else {
        setIsBuffering(false);
      }

      if (playbackStatus.isPlaying) {
        setIsBuffering(false);
        // Update your UI for the playing state
      } else {
        setIsBuffering(true);
        // Update your UI for the paused state
      }
      console.log('plaaa', playbackStatus?.didJustFinish);

      if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
        setTimeout(() => {
          onEnd();
        }, 300);
      }
    }
  };

  const onEnd = () => {
    if (video?.current) {
      video?.current.pauseAsync();
      setIsBuffering(false);
      setShouldPlay(false);
      navigation.replace('WhatsNext');
    }
  };

  return (
    <View style={styles.container}>
      {video && (
        <Video
          ref={video}
          style={styles.video}
          videoStyle={{
            height: '100%',
            width: '100%',
          }}
          source={require('../assets/images/video.mp4')}
          shouldPlay={shouldPlay}
          resizeMode={ResizeMode.COVER}
          onPlaybackStatusUpdate={status => _onPlaybackStatusUpdate(status)}
        />
      )}

      <TouchableOpacity
        onPress={() => {
          const analytics = firebase.analytics();
          analytics.logEvent(SOT_CLOSE_FOUNDER_VIDEO, {});
          onEnd();
        }}
        hitSlop={100}
        style={styles.crossView}
      >
        <Image style={styles.img} source={require('../assets/images/close.png')} />
      </TouchableOpacity>
      <View style={styles.content}>
        {isBuffering && (
          <ActivityIndicator style={styles.loadingIndicator} size="large" color="#FFFFFF" />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: globalStyles.container.width,
    alignSelf: 'center',
  },

  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIndicator: {
    position: 'absolute',
    zIndex: 1,
  },
  crossView: {
    position: 'absolute',
    marginTop: globalStyles.container.height < 800 ? 10 : 20,
    zIndex: 1000,
    right: 20,
  },
  img: { height: 30, width: 30, tintColor: 'white' },
});
