import { ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import 'cropperjs/dist/cropper.css';
import { globalStyles } from '../Global/GlobalStyles';
import ImagePickerBox from '../components/ImagePickerBox';
import { SoTContext } from '../Global/SoTContext';

// const defaultSrc =
//   "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";
const defaultSrc = '';

const atLeastOneObjectHasProperties = array => {  
  // Iterate through the array
  for (const obj of array) {
    // Check if the object has any properties
    if (Object.keys(obj)?.length > 0) {
      return true; // Return true if any object has properties
    }
  }
  return false; // Return false if no object has properties
};

type Props = NativeStackScreenProps<SOTFlowType>;
export default function ShareMediaScreen({ navigation }: Props) {
  console.log('ShareMediaScreen');

  const [shareImages, setShareImages] = useState([{}, {}, {}, {}]);
  const [selectedPic, setSelectedPic] = useState(-1);
  const [loader, setLoader] = useState(false);
  const { setImageData, imageData,user,mobile } = useContext(SoTContext);
  
  useEffect(() => {
    const updatedImages = [...imageData, ...Array(4 - imageData.length).fill({})].slice(0, 4);
    
    setShareImages(updatedImages);
  }, [imageData])

  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="ShareInsight"
        props={navigation}
        steps={[1, 1, 1, 1, 1, 1, 1]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView contentContainerStyle={{ paddingHorizontal: 20, width: '100%' }}>
        <View
          style={{
            alignItems: 'flex-start',
          }}
        >
          <Text style={styles.title}>Share photos</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>Upload pictures of you two together.</Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={styles.userInfoEntry}>
          <Text style={styles.fieldTopLabel}>Upload Photos</Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {shareImages.map((item, index) => (
              <ImagePickerBox
                index={index}
                setSelectedPic={setSelectedPic}
                selectedPic={selectedPic}
                shareImage={item}
                setShareImages={setShareImages}
                setLoader={setLoader}
              />
            ))}
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height >= 800 ? 20 : 10,
          paddingHorizontal: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ButtonStandard
            disabled={user.phone !== mobile.replace(/[^\w]/g, '') &&
              (loader || !atLeastOneObjectHasProperties(shareImages))}
            label="Review Intro"
            //onPress={() => navigation.navigate('ShareMediaReview')}
            onPress={() => {
              if (user.phone === mobile.replace(/[^\w]/g, '')) {
                navigation.navigate('Review');
              } else {
                const newArray = [...shareImages];
                if (selectedPic !== -1) {
                  const temp = newArray[0];
                  newArray[0] = newArray[selectedPic];
                  newArray[selectedPic] = temp;
                }
                const filteredArray = newArray.filter(obj => Object.keys(obj).length !== 0);
                setImageData(filteredArray);

                navigation.navigate('Review');
              }
            }}
          />
        </div>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  uploadText: {
    fontFamily: 'Jost Regular',
    color: '#ADB5BD',
    fontSize: 18,
  },
  uploadBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: globalStyles.container.width - 50,
    borderWidth: 2,
    borderRadius: 8,
    height: 180,
    borderColor: '#ADB5BD',
  },
  title: {
    paddingRight: 25,
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 15,
    marginBottom: 0,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '100%',
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 6,
  },
  userInfoEntry: {
    width: '100%',
    marginTop: 20,
  },
  liftedSection: {
    width: 360,
    shadowColor: '#bbbbbb',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 15,
    paddingTop: 10,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
    paddingLeft: 15,
  },
  liftedHeaderTextSmall: {
    color: '#093F76',
    fontSize: 16,
    paddingRight: 20,
    fontFamily: 'Ubuntu Medium',
    paddingBottom: 10,
  },
  mediaSelected: {
    borderRadius: 10,
    borderWidth: 2,
    backgroundColor: '#ffffff',
    borderStyle: 'dashed',
    borderColor: '#134b85',
    marginBottom: 10,
    marginRight: 25,
    width: 150,
    height: 150,
  },
  mediaText: {
    color: '#333333',
    fontFamily: 'Jost Regular',
  },
});
